import { useState } from "react";
import { twMerge } from "tailwind-merge";
import Column from "@components/Ui/Column";
import Icon from "@components/Ui/Icon";
import Clickable from "@components/Ui/Clickable";
import { useVendorContext } from "@contexts/vendor-context";
import NavItem from "@components/SideNav/NavItem";
import { useSesamyAuthTokenContext } from "@contexts/sesamy-auth-token-context";
import userHasScopes from "@components/VisibleForScopes/userHasScopes";
import { useRouter } from "next/router";
import Row from "@components/Ui/Row";
import navItems, { getActivePageIds } from "@utils/navigation";
import { showNewMessage } from "@intercom/messenger-js-sdk";

const getNavStorage = () => {
  const navStorage = localStorage.getItem("navStorage");
  return navStorage ? JSON.parse(navStorage) : {};
};

export default () => {
  const navStorage = getNavStorage();
  const [expanded, setExpanded] = useState(
    navStorage.hasOwnProperty("expanded") ? navStorage.expanded : true,
  );
  const { pathname } = useRouter();
  const [activePageId, activeSubPageId] = getActivePageIds(pathname);
  const navItemsOpenedState = useState(
    navItems.reduce(
      (a, { id }) => ({ ...a, [id]: id === activePageId ? true : false }),
      {},
    ),
  );

  const { selectedVendor } = useVendorContext();
  const startPageUrl = selectedVendor ? `/${selectedVendor}/analytics` : "/";

  const { scopesWeHaveForVendor } = useSesamyAuthTokenContext();

  const expandMenu = () => {
    const navStorage = getNavStorage();

    const toggledExpanded = !expanded;

    localStorage.setItem(
      "navStorage",
      JSON.stringify({
        ...navStorage,
        expanded: toggledExpanded,
      }),
    );

    setExpanded(toggledExpanded);
  };

  const filteredNavItems = scopesWeHaveForVendor
    ? navItems.filter(({ scopes }) =>
        userHasScopes(scopes, scopesWeHaveForVendor),
      )
    : [];

  return (
    <aside className="sticky top-0 z-20 h-screen p-2">
      <div
        className={twMerge(
          "h-full w-[72px] rounded-2xl bg-gray-800 py-2 transition-[width] duration-300",
          expanded && "w-[258px] overflow-hidden",
        )}
      >
        <div
          className={twMerge(
            "h-full px-4 py-2",
            expanded &&
              "custom-scrollbar scrollbar-gray-800 overflow-x-hidden overflow-y-scroll overscroll-none px-0 pl-4",
          )}
        >
          <Column className="h-full justify-between gap-12" up left>
            <div className="w-full space-y-12">
              <Clickable
                href={startPageUrl}
                className="w-full overflow-hidden p-1.5 row-left"
              >
                <div>
                  <Icon className="text-2xl sm:text-2xl" name="sesamy-portal" />
                </div>
              </Clickable>
              <Column className="w-full gap-2" left>
                {filteredNavItems.map((navItem) => (
                  <NavItem
                    key={navItem.id}
                    navItem={navItem}
                    expanded={expanded}
                    activePageId={activePageId}
                    activeSubPageId={activeSubPageId}
                    navItemsOpenedState={navItemsOpenedState}
                  />
                ))}
              </Column>
            </div>
            <Row
              className={twMerge(
                "!flex-nowrap gap-1 whitespace-nowrap p-4 text-sm text-gray-300 transition-opacity duration-300 sm:text-sm",
                !expanded && "pointer-events-none opacity-0",
              )}
            >
              Need help?{" "}
              <button
                className="text-sm text-white hover:cursor-pointer hover:underline sm:text-sm"
                onClick={() => showNewMessage("")}
              >
                Contact support
              </button>
            </Row>
          </Column>
          <Clickable
            className="absolute bottom-12 right-0 h-8 w-8 rounded bg-gray-600 transition-colors duration-75 row hover:bg-gray-400"
            onClick={expandMenu}
          >
            <Icon
              className={twMerge(
                "translate-x-px text-xs text-gray-300 transition-transform duration-75 sm:text-xs",
                expanded && "-translate-x-px scale-x-[-1]",
              )}
              name="chevron-right"
            />
          </Clickable>
        </div>
      </div>
    </aside>
  );
};
