import { useEffect, useState } from "react";
import { useAuth0, type User as Auth0User } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import Intercom, { update } from "@intercom/messenger-js-sdk";
import { useVendorContext } from "@contexts/vendor-context";
import { useRouter } from "next/router";

const ERROR_IGNORE_LIST = ["ResizeObserver loop limit exceeded"];

const initializeDatadog = (user: Auth0User) => {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== "production") {
    return;
  }

  datadogRum.init({
    applicationId: "c7fcdbcc-b66b-4f9c-b475-8a04f5c02ea8",
    clientToken: "pub2dfaadbfe92b58f818a76e0c6bad6dd4",
    site: "datadoghq.eu",
    service: "insights",
    env: "production",
    version: "1.0.0",
    sampleRate: 100,
    sessionReplaySampleRate: 100,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    beforeSend: (
      _,
      // this has type but they're wrong.  have to cast to any... typescript says error key doesn't exist but does from logs
      context: any,
    ) => {
      if (context.error && ERROR_IGNORE_LIST.includes(context.error)) {
        return false;
      }
    },
  });

  datadogRum.startSessionReplayRecording();

  datadogRum.setUser({
    sub: user.sub,
    email: user.email || undefined, // TypeScript dance for RUM. Can't get RUM's User type from package
    name: user.name || undefined,
  });
};

export default () => {
  const { user } = useAuth0();
  const router = useRouter();
  const { selectedVendor, getVendorSettings } = useVendorContext();
  const vendorSettings = getVendorSettings(selectedVendor);
  const [hasInitializedDatadog, setHasInitializedDatadog] = useState(false);
  const [hasInitializedIntercom, setHasInitializedIntercom] = useState(false);

  useEffect(() => {
    try {
      if (user && !hasInitializedDatadog) {
        setHasInitializedDatadog(true);
        initializeDatadog(user);
      }
      if (user && vendorSettings && !hasInitializedIntercom) {
        setHasInitializedIntercom(true);
        const intercomSettings = {
          app_id: "rhhdaakf",
          user_id: user.sub,
          name: user.name,
          email: user.email,
          avatar: user.picture
            ? {
                type: "avatar",
                image_url: user.picture,
              }
            : undefined,
          company: vendorSettings
            ? {
                id: vendorSettings.vendorId,
                name: vendorSettings.name,
                website: vendorSettings.siteUrl,
                country: vendorSettings.country,
                currency: vendorSettings.currency,
                plan: "portal-default",
              }
            : undefined,
          hide_default_launcher: false,
          custom_launcher_selector: "[data-intercom-custom-launcher]",
          action_color: "#6B53F3",
          background_color: "#6B53F3",
          vertical_padding: 26,
          horizontal_padding: 26,
        };
        Intercom(intercomSettings);
        router.events.on("routeChangeComplete", () => {
          update(intercomSettings);
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, [
    user,
    hasInitializedDatadog,
    hasInitializedIntercom,
    vendorSettings,
    router.events,
  ]);

  return null;
};
